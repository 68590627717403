import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Scrollspy from "react-scrollspy";
import "semantic-ui-css/semantic.min.css";
import EmailIcon from "@material-ui/icons/Email";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import ParticlesBg from "particles-bg";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

function Contact() {
  return (
    <MediaContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us</title>
        <meta
          name="description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, jika anda ingin membuat website dan aplikasi, silahkan hubungi kami, kami akan memberikan harga terbaik sesuai dengan kebutuhan anda, begitu pula dengan kualitas pekerjaan yang sesuai standard pembuatan pada umumnya"
        ></meta>
        <meta
          name="keywords"
          content="website developer, website developer jakarta, website developer indonesia, android developer, android developer console, android developer mode, android developer tools, react native developer, software house, web developer indonesia, developer indonesia, android developer indonesia, freelance web developer indonesia, web developer indonesia terbaik, front end developer indonesia, app developer indonesia, website developer indonesia, mobile app developer indonesia, basudara, basudarateam, basudara team, team basudara, basudara developer, basudara dev, dev basudara, dev basudara team, developer team basudara, basudara developer Indonesia, developer Indonesia apps website, developer Indonesia website apps"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta name="author" content="Basudara Team"></meta>
        <link rel="canonical" href="https://basudarateam.com/contact" />
      </Helmet>
      <Scrollspy
        items={[
          "section-1",
          "section-2",
          "section-3",
          "section-4",
          "section-5",
        ]}
        currentClassName="is-current"
      >
        <Media at="mobile">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 20,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>

        <Media greaterThanOrEqual="tablet">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}

              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 30,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  About
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Product
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Client
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>
      </Scrollspy>

      <div>
        <div
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
        </div>

        <Media at="mobile">
          <div>
            <div
              style={{
                backgroundColor: "transparent",
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                zIndex: "-1",
              }}
            >
              <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
            </div>
            <section
              id="section-5"
              style={{
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Container text>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    Contact Us
                  </b>
                </div>
                <a
                  href="mailto:basudarateamindo@gmail.com"
                  target="_blank"
                  style={{
                    color: "#606060",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon fontSize="large" />
                    {"   "}
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 15,
                        textAlign: "center",
                        marginLeft: 10,
                        color: "#ff0066",
                        textDecorationLine: "underline",
                      }}
                    >
                      basudarateamindo@gmail.com
                    </b>
                  </div>
                </a>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: 15,
                    marginTop: 15,
                  }}
                >
                  If you need services to create websites, android and IOS
                  applications, you can contact us by email.
                </p>
                {/* <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <WhatsAppIcon fontSize="large" />
            </div>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <PhoneIcon fontSize="large" />
            </div> */}
              </Container>
            </section>
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div>
            <div
              style={{
                backgroundColor: "transparent",
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                zIndex: "-1",
              }}
            >
              <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
            </div>
            <section
              id="section-5"
              style={{
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Container text>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    Contact Us
                  </b>
                </div>
                <a
                  href="mailto:basudarateamindo@gmail.com"
                  target="_blank"
                  style={{
                    color: "#606060",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <EmailIcon fontSize="large" />
                    {"   "}
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 20,
                        textAlign: "center",
                        marginLeft: 10,
                        color: "#ff0066",
                        textDecorationLine: "underline",
                      }}
                    >
                      basudarateamindo@gmail.com
                    </b>
                  </div>
                </a>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    fontSize: 15,
                    marginTop: 15,
                  }}
                >
                  If you need services to create websites, android and IOS
                  applications, you can contact us by email or our{" "}
                  <a
                    href="https://api.whatsapp.com/send?phone=+6285972735680&text=Hi,%20saya%20tau%20basudara%20dari%20website."
                    target="_blank"
                    style={{
                      color: "#ff0066",
                      textDecorationLine: "underline",
                    }}
                  >
                    WhatsApp
                  </a>
                  .
                </p>
              </Container>
            </section>
          </div>
        </Media>
      </div>
    </MediaContextProvider>
  );
}

export default Contact;
