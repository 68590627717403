import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Scrollspy from "react-scrollspy";
import "semantic-ui-css/semantic.min.css";
import EmailIcon from "@material-ui/icons/Email";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import ParticlesBg from "particles-bg";
import LazyLoad from "react-lazyload";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

function Home() {
  return (
    <MediaContextProvider>
      <Scrollspy
        items={[
          "section-1",
          "section-2",
          "section-3",
          "section-4",
          "section-5",
        ]}
        currentClassName="is-current"
      >
        <Media at="mobile">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 20,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="#section-5"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>

        <Media greaterThanOrEqual="tablet">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}

              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 30,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="#section-2"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  About
                </Button>
              </a>
              <a
                href="#section-3"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Product
                </Button>
              </a>
              <a
                href="#section-4"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Client
                </Button>
              </a>
              <a
                href="#section-5"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>
      </Scrollspy>

      <div>
        <div
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
        </div>
        <section
          id="section-1"
          style={{
            backgroundColor: "transparent",
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container text>
            <div>
              <b
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 40,
                  textAlign: "center",
                }}
              >
                Hi,{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  good
                </b>{" "}
                to see you
              </b>
            </div>
            <div
              style={{
                marginTop: 10,
              }}
            >
              <b
                style={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: 20,
                  textAlign: "center",
                }}
              >
                We are a{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  community of programmers{" "}
                </b>
                who have{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  great abilities
                </b>{" "}
                both individually and in teams, can always{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  work anytime and anywhere
                </b>
                , we can always{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  communicate well
                </b>
                , and who always{" "}
                <b
                  style={{
                    color: "#ff0066",
                  }}
                >
                  pay attention to the quality
                </b>{" "}
                of the project we provide.
              </b>
            </div>
          </Container>
        </section>
        <LazyLoad height={200} once>
          <Media at="mobile">
            <div>
              <section
                id="section-2"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <div style={{}}>
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 40,

                        textAlign: "center",
                      }}
                    >
                      We are
                      <b
                        style={{
                          color: "#ff0066",
                        }}
                      >
                        {" "}
                        certified{" "}
                      </b>
                      using
                    </b>

                    <Grid
                      columns={3}
                      style={{
                        marginTop: 15,
                        paddingLeft: 20,
                      }}
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <Image
                            alt="jquery"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo5.png?alt=media&token=1328efcb-01e2-4b3b-99d9-10ffb3f229c8"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="github"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fgithub_PNG40.png?alt=media&token=a5fc8298-5c2d-45d7-a43e-e83ff56fc56c"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="react"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo6.png?alt=media&token=36d1425e-2a34-47da-9820-43e9c6381662"
                          />
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column>
                          <Image
                            alt="firebase"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo-standard.png?alt=media&token=461938e6-230b-481f-b0b9-4d8e75f720b0"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="mysql"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo4.png?alt=media&token=12267c92-3bfd-48e0-a7c5-15110931a07e"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="php"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo7.png?alt=media&token=cb0a3af0-286a-4714-a595-9c0fb6f4e76a"
                          />
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column>
                          <Image
                            alt="css"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo2.png?alt=media&token=07a575d5-92e9-4879-a0ec-254a50191cb4"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="html"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo1.png?alt=media&token=c094f614-0121-45af-8288-27e98b9a4ac3"
                          />
                        </Grid.Column>
                        <Grid.Column>
                          <Image
                            alt="javascript"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo3.png?alt=media&token=f1aac855-f4ee-40ee-8aac-2dbe177eb51c"
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Container>
              </section>
              <div
                style={{
                  backgroundColor: "transparent",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: "-1",
                }}
              >
                <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
              </div>
              <section
                id="section-3"
                style={{
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Container text>
                  <div style={{}}>
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 40,
                        textAlign: "center",
                      }}
                    >
                      Our Own <b style={{ color: "#ff0066" }}>Product</b>
                    </b>

                    <Grid
                      columns={2}
                      style={{
                        marginTop: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.sebarin.sebarin"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              style={{
                                marginBottom: 10,
                              }}
                              rounded
                              alt="sebarin"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fsebarin.png?alt=media&token=3ef91571-110b-4b21-a329-66cd33a20e7a"
                            />
                          </a>
                        </Grid.Column>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.iventid.ivent"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="iventid"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fivent.png?alt=media&token=f4104d12-0375-4146-a642-94dc923a0886"
                            />
                          </a>
                        </Grid.Column>

                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.gage.gage"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="ganjilganep"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fgage.png?alt=media&token=54aefecd-a426-4ec7-b005-9efe309a6a3f"
                            />
                          </a>
                        </Grid.Column>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.basudara.crackthecore"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="crackthecode"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fcrackthecode.png?alt=media&token=e13e45c3-8ae6-4adb-ae70-fba887c296f7"
                            />
                          </a>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Container>
              </section>
              <section
                id="section-4"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    We build for <b style={{ color: "#ff0066" }}>them</b>
                  </b>

                  <Grid
                    columns={3}
                    style={{
                      marginTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="sinarindoutama"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fsiup.png?alt=media&token=761a4922-a46c-4ad1-9828-d2fb3dc72065"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="billiard32"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbilliard.png?alt=media&token=8c1ae445-0f81-450a-b566-ed2b80c3f3a6"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="dishub"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp2.png?alt=media&token=170c13cf-6a65-4a9f-b244-926c35feaab5"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="digima"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp3.png?alt=media&token=210df8bd-4803-4fd8-a0bd-c4d2a28c455f"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="bpbd"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbpbd.png?alt=media&token=d966f5f4-ec5a-45ca-8ee5-d7ce548d1d24"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </section>
              <div
                style={{
                  backgroundColor: "transparent",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: "-1",
                }}
              >
                <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
              </div>
              <section
                id="section-5"
                style={{
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 40,
                        textAlign: "center",
                      }}
                    >
                      Contact Us
                    </b>
                  </div>
                  <a
                    href="mailto:basudarateamindo@gmail.com"
                    target="_blank"
                    style={{
                      color: "#606060",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <EmailIcon fontSize="large" />
                      {"   "}
                      <b
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: 15,
                          textAlign: "center",
                          marginLeft: 10,
                          color: "#ff0066",
                          textDecorationLine: "underline",
                        }}
                      >
                        basudarateamindo@gmail.com
                      </b>
                    </div>
                  </a>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 15,
                      marginTop: 15,
                    }}
                  >
                    If you need services to create websites, android and IOS
                    applications, you can contact us by email or our{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=+6285972735680&text=Hi,%20saya%20tau%20basudara%20dari%20website."
                      target="_blank"
                      style={{
                        color: "#ff0066",
                        textDecorationLine: "underline",
                      }}
                    >
                      WhatsApp
                    </a>
                    .
                  </p>
                  {/* <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <WhatsAppIcon fontSize="large" />
            </div>
            <div
              style={{
                display: "flex",
                height: "100%",
                alignItems: "center",
              }}
            >
              <PhoneIcon fontSize="large" />
            </div> */}
                </Container>
              </section>
            </div>
          </Media>
          <Media greaterThanOrEqual="tablet">
            <div>
              <section
                id="section-2"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <Grid columns={2}>
                    <Grid.Row>
                      <Grid.Column>
                        <div
                          style={{
                            display: "flex",
                            height: "100%",
                            alignItems: "center",
                          }}
                        >
                          <b
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "600",
                              fontSize: 30,
                              textAlign: "center",
                            }}
                          >
                            We are<br></br>
                            <b
                              style={{
                                color: "#ff0066",
                              }}
                            >
                              {" "}
                              certified
                            </b>
                            <br></br>
                            using
                          </b>
                        </div>
                      </Grid.Column>
                      <Grid.Column>
                        <Grid columns={3}>
                          <Grid.Row>
                            <Grid.Column>
                              <Image
                                alt="jquery"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo5.png?alt=media&token=1328efcb-01e2-4b3b-99d9-10ffb3f229c8"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="github"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fgithub_PNG40.png?alt=media&token=a5fc8298-5c2d-45d7-a43e-e83ff56fc56c"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="react"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo6.png?alt=media&token=36d1425e-2a34-47da-9820-43e9c6381662"
                              />
                            </Grid.Column>
                          </Grid.Row>

                          <Grid.Row>
                            <Grid.Column>
                              <Image
                                alt="firebase"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo-standard.png?alt=media&token=461938e6-230b-481f-b0b9-4d8e75f720b0"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="mysql"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo4.png?alt=media&token=12267c92-3bfd-48e0-a7c5-15110931a07e"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="php"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo7.png?alt=media&token=cb0a3af0-286a-4714-a595-9c0fb6f4e76a"
                              />
                            </Grid.Column>
                          </Grid.Row>
                          <Grid.Row>
                            <Grid.Column>
                              <Image
                                alt="css"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo2.png?alt=media&token=07a575d5-92e9-4879-a0ec-254a50191cb4"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="html"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo1.png?alt=media&token=c094f614-0121-45af-8288-27e98b9a4ac3"
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Image
                                alt="javascript"
                                src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo3.png?alt=media&token=f1aac855-f4ee-40ee-8aac-2dbe177eb51c"
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </section>
              <div
                style={{
                  backgroundColor: "transparent",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: "-1",
                }}
              >
                <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
              </div>
              <section
                id="section-3"
                style={{
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Container text>
                  <div style={{}}>
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 40,
                        textAlign: "center",
                      }}
                    >
                      Our Own <b style={{ color: "#ff0066" }}>Product</b>
                    </b>

                    <Grid
                      columns={4}
                      divided
                      style={{
                        marginTop: 10,
                        paddingLeft: 20,
                      }}
                    >
                      <Grid.Row>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.sebarin.sebarin"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="sebarin"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fsebarin.png?alt=media&token=3ef91571-110b-4b21-a329-66cd33a20e7a"
                            />
                          </a>
                        </Grid.Column>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.iventid.ivent"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="iventid"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fivent.png?alt=media&token=f4104d12-0375-4146-a642-94dc923a0886"
                            />
                          </a>
                        </Grid.Column>

                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.gage.gage"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="ganjilganep"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fgage.png?alt=media&token=54aefecd-a426-4ec7-b005-9efe309a6a3f"
                            />
                          </a>
                        </Grid.Column>
                        <Grid.Column>
                          <a
                            href="https://play.google.com/store/apps/details?id=com.basudara.crackthecore"
                            target="_blank"
                            style={{
                              color: "#606060",
                            }}
                          >
                            <Image
                              alt="crackthecode"
                              rounded
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fcrackthecode.png?alt=media&token=e13e45c3-8ae6-4adb-ae70-fba887c296f7"
                            />
                          </a>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </Container>
              </section>
              <section
                id="section-4"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    We build for <b style={{ color: "#ff0066" }}>them</b>
                  </b>

                  <Grid
                    columns={5}
                    style={{
                      marginTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="sinarindoutama"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fsiup.png?alt=media&token=761a4922-a46c-4ad1-9828-d2fb3dc72065"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="billiard32"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbilliard.png?alt=media&token=8c1ae445-0f81-450a-b566-ed2b80c3f3a6"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="dishub"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp2.png?alt=media&token=170c13cf-6a65-4a9f-b244-926c35feaab5"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="digima"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp3.png?alt=media&token=210df8bd-4803-4fd8-a0bd-c4d2a28c455f"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="bpbd"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbpbd.png?alt=media&token=d966f5f4-ec5a-45ca-8ee5-d7ce548d1d24"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </section>
              <div
                style={{
                  backgroundColor: "transparent",
                  height: "100%",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "absolute",
                  zIndex: "-1",
                }}
              >
                <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
              </div>
              <section
                id="section-5"
                style={{
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <div
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                    }}
                  >
                    <b
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "600",
                        fontSize: 40,
                        textAlign: "center",
                      }}
                    >
                      Contact Us
                    </b>
                  </div>
                  <a
                    href="mailto:basudarateamindo@gmail.com"
                    target="_blank"
                    style={{
                      color: "#606060",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        alignItems: "center",
                      }}
                    >
                      <EmailIcon fontSize="large" />
                      {"   "}
                      <b
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "600",
                          fontSize: 20,
                          textAlign: "center",
                          marginLeft: 10,
                          color: "#ff0066",
                          textDecorationLine: "underline",
                        }}
                      >
                        basudarateamindo@gmail.com
                      </b>
                    </div>
                  </a>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 15,
                      marginTop: 15,
                    }}
                  >
                    If you need services to create websites, android and IOS
                    applications, you can contact us by email or our{" "}
                    <a
                      href="https://api.whatsapp.com/send?phone=+6285972735680&text=Hi,%20saya%20tau%20basudara%20dari%20website."
                      target="_blank"
                      style={{
                        color: "#ff0066",
                        textDecorationLine: "underline",
                      }}
                    >
                      WhatsApp
                    </a>
                    .
                  </p>
                </Container>
              </section>
            </div>
          </Media>
        </LazyLoad>
      </div>
    </MediaContextProvider>
  );
}

export default Home;
