import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Scrollspy from "react-scrollspy";
import "semantic-ui-css/semantic.min.css";
import EmailIcon from "@material-ui/icons/Email";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import ParticlesBg from "particles-bg";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

function Client() {
  return (
    <MediaContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Client Basudara</title>
        <meta
          name="description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami telah bekerja sama dengan swasta maupun pemerintah dalam mengembangkan perangkat lunak sesuai dengan kebutuhannya masing-masing"
        ></meta>
        <meta
          name="keywords"
          content="website developer, website developer jakarta, website developer indonesia, android developer, android developer console, android developer mode, android developer tools, react native developer, software house, web developer indonesia, developer indonesia, android developer indonesia, freelance web developer indonesia, web developer indonesia terbaik, front end developer indonesia, app developer indonesia, website developer indonesia, mobile app developer indonesia, basudara, basudarateam, basudara team, team basudara, basudara developer, basudara dev, dev basudara, dev basudara team, developer team basudara, basudara developer Indonesia, developer Indonesia apps website, developer Indonesia website apps"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta name="author" content="Basudara Team"></meta>
        <link rel="canonical" href="https://basudarateam.com/client" />
      </Helmet>
      <Scrollspy
        items={[
          "section-1",
          "section-2",
          "section-3",
          "section-4",
          "section-5",
        ]}
        currentClassName="is-current"
      >
        <Media at="mobile">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 20,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>

        <Media greaterThanOrEqual="tablet">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}

              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 30,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  About
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Product
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Client
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>
      </Scrollspy>

      <div>
        <div
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
        </div>

        <Media at="mobile">
          <div>
            <section
                id="section-4"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    We build for <b style={{ color: "#ff0066" }}>them</b>
                  </b>

                  <Grid
                    columns={3}
                    style={{
                      marginTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="sinarindoutama"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fsiup.png?alt=media&token=761a4922-a46c-4ad1-9828-d2fb3dc72065"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="billiard32"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbilliard.png?alt=media&token=8c1ae445-0f81-450a-b566-ed2b80c3f3a6"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="dishub"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp2.png?alt=media&token=170c13cf-6a65-4a9f-b244-926c35feaab5"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="digima"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp3.png?alt=media&token=210df8bd-4803-4fd8-a0bd-c4d2a28c455f"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="bpbd"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbpbd.png?alt=media&token=d966f5f4-ec5a-45ca-8ee5-d7ce548d1d24"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </section>
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div>
            
            <section
                id="section-4"
                style={{
                  backgroundColor: "#f6f6f6",
                  height: "100vh",
                  width: "100%",
                  paddingTop: 20,
                  paddingBottom: 20,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Container text>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    We build for <b style={{ color: "#ff0066" }}>them</b>
                  </b>

                  <Grid
                    columns={5}
                    style={{
                      marginTop: 10,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="sinarindoutama"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fsiup.png?alt=media&token=761a4922-a46c-4ad1-9828-d2fb3dc72065"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="billiard32"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbilliard.png?alt=media&token=8c1ae445-0f81-450a-b566-ed2b80c3f3a6"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="dishub"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp2.png?alt=media&token=170c13cf-6a65-4a9f-b244-926c35feaab5"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          alt="digima"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fcomp3.png?alt=media&token=210df8bd-4803-4fd8-a0bd-c4d2a28c455f"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          className="imageLogo"
                          circular
                          alt="bpbd"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fclient%2Fbpbd.png?alt=media&token=d966f5f4-ec5a-45ca-8ee5-d7ce548d1d24"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Container>
              </section>
          </div>
        </Media>
      </div>
    </MediaContextProvider>
  );
}

export default Client;
