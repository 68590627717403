import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Scrollspy from "react-scrollspy";
import "semantic-ui-css/semantic.min.css";
import EmailIcon from "@material-ui/icons/Email";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import ParticlesBg from "particles-bg";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

function About() {
  return (
    <MediaContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tentang Basudara</title>
        <meta
          name="description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami cukup menguasai teknik-teknik untuk membuat perangkat lunak platform website dan mobile (aplikasi)"
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://basudarateam.com/" />
        <meta property="og:title" content="Tentang Basudara" />
        <meta
          property="og:description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami cukup menguasai teknik-teknik untuk membuat perangkat lunak platform website dan mobile (aplikasi)"
        />
        <meta
          property="og:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />

        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://basudarateam.com/" />
        <meta property="twitter:title" content="Tentang Basudara" />
        <meta
          property="twitter:description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami cukup menguasai teknik-teknik untuk membuat perangkat lunak platform website dan mobile (aplikasi)"
        />
        <meta
          property="twitter:image"
          content="https://metatags.io/assets/meta-tags-16a33a6a8531e519cc0936fbba0ad904e52d35f34a46c97a2c9f6f7dd7d336f2.png"
        />
        <meta
          name="keywords"
          content="website developer, website developer jakarta, website developer indonesia, android developer, android developer console, android developer mode, android developer tools, react native developer, software house, web developer indonesia, developer indonesia, android developer indonesia, freelance web developer indonesia, web developer indonesia terbaik, front end developer indonesia, app developer indonesia, website developer indonesia, mobile app developer indonesia, basudara, basudarateam, basudara team, team basudara, basudara developer, basudara dev, dev basudara, dev basudara team, developer team basudara, basudara developer Indonesia, developer Indonesia apps website, developer Indonesia website apps"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta name="author" content="Basudara Team"></meta>
        <link rel="canonical" href="https://basudarateam.com/about" />
      </Helmet>
      <Scrollspy
        items={[
          "section-1",
          "section-2",
          "section-3",
          "section-4",
          "section-5",
        ]}
        currentClassName="is-current"
      >
        <Media at="mobile">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 20,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>

        <Media greaterThanOrEqual="tablet">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}

              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 30,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  About
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Product
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Client
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>
      </Scrollspy>

      <div>
        <div
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
        </div>

        <Media at="mobile">
          <div>
            <section
              id="section-2"
              style={{
                backgroundColor: "#f6f6f6",
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Container text>
                <div style={{}}>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,

                      textAlign: "center",
                    }}
                  >
                    We are
                    <b
                      style={{
                        color: "#ff0066",
                      }}
                    >
                      {" "}
                      certified{" "}
                    </b>
                    using
                  </b>

                  <Grid
                    columns={3}
                    style={{
                      marginTop: 15,
                      paddingLeft: 20,
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          alt="jquery"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo5.png?alt=media&token=1328efcb-01e2-4b3b-99d9-10ffb3f229c8"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="github"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fgithub_PNG40.png?alt=media&token=a5fc8298-5c2d-45d7-a43e-e83ff56fc56c"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="react"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo6.png?alt=media&token=36d1425e-2a34-47da-9820-43e9c6381662"
                        />
                      </Grid.Column>
                    </Grid.Row>

                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          alt="firebase"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo-standard.png?alt=media&token=461938e6-230b-481f-b0b9-4d8e75f720b0"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="mysql"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo4.png?alt=media&token=12267c92-3bfd-48e0-a7c5-15110931a07e"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="php"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo7.png?alt=media&token=cb0a3af0-286a-4714-a595-9c0fb6f4e76a"
                        />
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column>
                        <Image
                          alt="css"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo2.png?alt=media&token=07a575d5-92e9-4879-a0ec-254a50191cb4"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="html"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo1.png?alt=media&token=c094f614-0121-45af-8288-27e98b9a4ac3"
                        />
                      </Grid.Column>
                      <Grid.Column>
                        <Image
                          alt="javascript"
                          src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo3.png?alt=media&token=f1aac855-f4ee-40ee-8aac-2dbe177eb51c"
                        />
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Container>
            </section>
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div>
            <section
              id="section-2"
              style={{
                backgroundColor: "#f6f6f6",
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Container text>
                <Grid columns={2}>
                  <Grid.Row>
                    <Grid.Column>
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          alignItems: "center",
                        }}
                      >
                        <b
                          style={{
                            fontFamily: "Poppins",
                            fontWeight: "600",
                            fontSize: 30,
                            textAlign: "center",
                          }}
                        >
                          We are<br></br>
                          <b
                            style={{
                              color: "#ff0066",
                            }}
                          >
                            {" "}
                            certified
                          </b>
                          <br></br>
                          using
                        </b>
                      </div>
                    </Grid.Column>
                    <Grid.Column>
                      <Grid columns={3}>
                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              alt="jquery"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo5.png?alt=media&token=1328efcb-01e2-4b3b-99d9-10ffb3f229c8"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="github"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fgithub_PNG40.png?alt=media&token=a5fc8298-5c2d-45d7-a43e-e83ff56fc56c"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="react"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo6.png?alt=media&token=36d1425e-2a34-47da-9820-43e9c6381662"
                            />
                          </Grid.Column>
                        </Grid.Row>

                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              alt="firebase"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo-standard.png?alt=media&token=461938e6-230b-481f-b0b9-4d8e75f720b0"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="mysql"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo4.png?alt=media&token=12267c92-3bfd-48e0-a7c5-15110931a07e"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="php"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo7.png?alt=media&token=cb0a3af0-286a-4714-a595-9c0fb6f4e76a"
                            />
                          </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                          <Grid.Column>
                            <Image
                              alt="css"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo2.png?alt=media&token=07a575d5-92e9-4879-a0ec-254a50191cb4"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="html"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo1.png?alt=media&token=c094f614-0121-45af-8288-27e98b9a4ac3"
                            />
                          </Grid.Column>
                          <Grid.Column>
                            <Image
                              alt="javascript"
                              src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Flogo3.png?alt=media&token=f1aac855-f4ee-40ee-8aac-2dbe177eb51c"
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Container>
            </section>
          </div>
        </Media>
      </div>
    </MediaContextProvider>
  );
}

export default About;
