import "./App.css";
import About from "./About";
import Home from "./Home";
import Client from "./Client";
import Contact from "./Contact";
import Product from "./Product";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/about">
          <About />
        </Route>
        <Route exact path="/product">
          <Product />
        </Route>
        <Route exact path="/contact">
          <Contact />
        </Route>
        <Route exact path="/client">
          <Client />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
