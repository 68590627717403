import "./App.css";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Scrollspy from "react-scrollspy";
import "semantic-ui-css/semantic.min.css";
import EmailIcon from "@material-ui/icons/Email";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Grid, Image } from "semantic-ui-react";
import { createMedia } from "@artsy/fresnel";
import ParticlesBg from "particles-bg";
import LazyLoad from "react-lazyload";
import { Helmet } from "react-helmet";
const { MediaContextProvider, Media } = createMedia({
  breakpoints: {
    mobile: 320,
    tablet: 768,
    computer: 992,
    largeScreen: 1200,
    widescreen: 1920,
  },
});

function Product() {
  return (
    <MediaContextProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Product Basudara</title>
        <meta
          name="description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami juga mengembangkan perangkat lunak kami sendiri, yang dapat diakses dan diinstall langsung melalui Google Play Store"
        ></meta>
        <meta
          name="description"
          content="Basudara Team Dev bergerak dibidang pengembangan perangkat lunak, kami telah bekerja sama dengan swasta maupun pemerintah dalam mengembangkan perangkat lunak sesuai dengan kebutuhannya masing-masing"
        ></meta>
        <meta
          name="keywords"
          content="website developer, website developer jakarta, website developer indonesia, android developer, android developer console, android developer mode, android developer tools, react native developer, software house, web developer indonesia, developer indonesia, android developer indonesia, freelance web developer indonesia, web developer indonesia terbaik, front end developer indonesia, app developer indonesia, website developer indonesia, mobile app developer indonesia, basudara, basudarateam, basudara team, team basudara, basudara developer, basudara dev, dev basudara, dev basudara team, developer team basudara, basudara developer Indonesia, developer Indonesia apps website, developer Indonesia website apps"
        />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="language" content="English" />
        <meta name="revisit-after" content="2 days" />
        <meta name="author" content="Basudara Team"></meta>
        <link rel="canonical" href="https://basudarateam.com/product" />
      </Helmet>
      <Scrollspy
        items={[
          "section-1",
          "section-2",
          "section-3",
          "section-4",
          "section-5",
        ]}
        currentClassName="is-current"
      >
        <Media at="mobile">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}
              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 20,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>

        <Media greaterThanOrEqual="tablet">
          <AppBar
            position="fixed"
            style={{
              backgroundColor: "white",
              fontWeight: "800",
              padding: 10,
              boxShadow: "0px 0.5px 0px #9E9E9E",
            }}
          >
            <Toolbar>
              {/* <IconButton edge="start" color="black" aria-label="menu">
              <MenuIcon />
            </IconButton> */}

              <Typography
                variant="h1"
                style={{
                  flexGrow: 1,
                  color: "#606060",
                  fontFamily: "Poppins",
                  fontWeight: "600",
                  fontSize: 30,
                }}
              >
                <b>Basudara</b> <b style={{ color: "#ff0066" }}>Team</b>
              </Typography>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  About
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Product
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                  color="inherit"
                >
                  Client
                </Button>
              </a>
              <a
                href="https://basudarateam.com/"
                style={{
                  color: "#606060",
                }}
              >
                <Button
                  style={{
                    fontFamily: "Poppins",
                    fontWeight: "600",
                    backgroundColor: "#ff0066",
                    color: "white",
                  }}
                  color="inherit"
                >
                  Contact Us
                </Button>
              </a>
            </Toolbar>
          </AppBar>
        </Media>
      </Scrollspy>

      <div>
        <div
          style={{
            backgroundColor: "transparent",
            height: "100%",
            width: "100%",
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            zIndex: "-1",
          }}
        >
          <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
        </div>

        <Media at="mobile">
          <div>
            <div
              style={{
                backgroundColor: "transparent",
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                zIndex: "-1",
              }}
            >
              <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
            </div>
            <section
              id="section-3"
              style={{
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container text>
                <div style={{}}>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    Our Own <b style={{ color: "#ff0066" }}>Product</b>
                  </b>

                  <Grid
                    columns={2}
                    style={{
                      marginTop: 10,
                      paddingLeft: 20,
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.sebarin.sebarin"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            style={{
                              marginBottom: 10,
                            }}
                            rounded
                            alt="sebarin"
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fsebarin.png?alt=media&token=3ef91571-110b-4b21-a329-66cd33a20e7a"
                          />
                        </a>
                      </Grid.Column>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.iventid.ivent"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="iventid"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fivent.png?alt=media&token=f4104d12-0375-4146-a642-94dc923a0886"
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.gage.gage"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="ganjilganep"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fgage.png?alt=media&token=54aefecd-a426-4ec7-b005-9efe309a6a3f"
                          />
                        </a>
                      </Grid.Column>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.basudara.crackthecore"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="crackthecode"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fcrackthecode.png?alt=media&token=e13e45c3-8ae6-4adb-ae70-fba887c296f7"
                          />
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Container>
            </section>
          </div>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <div>
            <div
              style={{
                backgroundColor: "transparent",
                height: "100%",
                width: "100%",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                zIndex: "-1",
              }}
            >
              <ParticlesBg type="cobweb" num="5" bg={true}></ParticlesBg>
            </div>
            <section
              id="section-3"
              style={{
                height: "100vh",
                width: "100%",
                paddingTop: 20,
                paddingBottom: 20,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Container text>
                <div style={{}}>
                  <b
                    style={{
                      fontFamily: "Poppins",
                      fontWeight: "600",
                      fontSize: 40,
                      textAlign: "center",
                    }}
                  >
                    Our Own <b style={{ color: "#ff0066" }}>Product</b>
                  </b>

                  <Grid
                    columns={4}
                    divided
                    style={{
                      marginTop: 10,
                      paddingLeft: 20,
                    }}
                  >
                    <Grid.Row>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.sebarin.sebarin"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="sebarin"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fsebarin.png?alt=media&token=3ef91571-110b-4b21-a329-66cd33a20e7a"
                          />
                        </a>
                      </Grid.Column>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.iventid.ivent"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="iventid"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fivent.png?alt=media&token=f4104d12-0375-4146-a642-94dc923a0886"
                          />
                        </a>
                      </Grid.Column>

                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.gage.gage"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="ganjilganep"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fgage.png?alt=media&token=54aefecd-a426-4ec7-b005-9efe309a6a3f"
                          />
                        </a>
                      </Grid.Column>
                      <Grid.Column>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.basudara.crackthecore"
                          target="_blank"
                          style={{
                            color: "#606060",
                          }}
                        >
                          <Image
                            alt="crackthecode"
                            rounded
                            src="https://firebasestorage.googleapis.com/v0/b/basudara77.appspot.com/o/newWeb%2Fproduct%2Fcrackthecode.png?alt=media&token=e13e45c3-8ae6-4adb-ae70-fba887c296f7"
                          />
                        </a>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </div>
              </Container>
            </section>
          </div>
        </Media>
      </div>
    </MediaContextProvider>
  );
}

export default Product;
